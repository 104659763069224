<template>
  <div class="m1">
    <div class="m1-h">
      <h2><a href="javascript:;" @click="back()"><i class="el-icon-arrow-left"></i></a>  keyword</h2>
    </div>
    <div class="m1-r1">
      <el-select v-model="searchData.sub_type_id" placeholder="请选择" style="margin-right:14px">
        <el-option v-for="item in sub" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-input style="width:216px; margin: 0 34px" v-model="searchData.keyword" placeholder="请输入关键词"></el-input>
      <el-button type="primary" @click="getList()">查询</el-button>
    </div>

    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="sub_type_name"
        label="三级工种">
      </el-table-column>
      <el-table-column
        prop="keyword"
        label="关键词">
      </el-table-column>
      <el-table-column
        label="操作">
        <template slot-scope="scope">
          <a @click="showDialog = true; current = scope.row" href="javascript:;">编辑</a>
        </template>
      </el-table-column>
    </el-table>
    <div class="m1-r2">
      <div>共<span class="m1-text1">{{total}}</span>条记录</div>
      <div>
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getList()"
          :current-page.sync="searchData.page"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="current.sub_type_name"
      :visible.sync="showDialog"
      >
      <ul class="m1-ul">
        <li v-for="(item, index) in current.children">
          <el-input v-model="item.name" style="width:100px; margin-right: 10px" placeholder="请输入关键词"></el-input>
          <a @click="del(index)" href="javascript:;">删除</a>
        </li>
      </ul>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="add()">新 增</el-button>
        <el-button type="primary" @click="save()">保 存</el-button>
      </div>
  </el-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import {
  keywordList, keywordSave, sub
} from '@/api/setting';
export default {
  name: 'index',
  data() {
    return {
      searchData: {
        sub_type_id: '',
        keyword: '',
        page: 1,
        page_size: 10
      },
      total: 10,
      tableData: [],
      showDialog: false,
      sub: [],
      current: {}
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted(){
    this.getList()
    this.getSub()
  },
  methods: {
    getList () {
      keywordList(this.searchData).then(res =>{
        console.log(res)
        this.total = res.data.data.total;
        this.tableData = res.data.data.data
        this.tableData.forEach((item) => {
          item.keyword = []
          item.children.forEach((item2) => {
            item.keyword.push(item2.name)
          })
          item.keyword = item.keyword.join(',')
        })
      })
    },
     save () {
      keywordSave({
        sub_type_id: this.current.sub_type_id,
        type_id: this.current.type_id,
        children: this.current.children,
      }).then(res =>{
        if (res.data.msg == 'ok') {
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
          this.showDialog = false;
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    },
    getSub () {
      sub().then(res =>{
        this.sub = res.data.data
      })
    },
    add () {
      this.current.children.push(
        {
          id: 0,
          name: ''
        }
      )
    },
    del (index) {
      this.current.children.splice(index, 1)
    },
    back() {
      history.back()
    }
  },
}
</script>
<style scoped lang="scss">
.m1-h{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.m1-r1{
  display: flex;
  margin-bottom: 30px;
}
.m1-r2{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.m1-ul{
  display: flex;
  flex-wrap: wrap;
}
.m1-ul li{
  list-style: none;
  margin-right: 20px;
  margin-bottom: 10px;
}
</style>
