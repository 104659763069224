import http from './http'

export const save = (params) => http.post(`config/save`,params)
export const detail = (params) => http.get(`config/detail`,params)
export const list = (params) => http.get(`crawl/token/list`,params)
export const areaLists = (params) => http.get(`area/lists`,params)
export const userSave = (params) => http.post(`crawl/token/save`,params)
export const userDelete = (params) => http.delete(`crawl/token/delete`,params)
export const keywordList = (params) => http.get(`crawl/keyword/list`,params)
export const keywordSave = (params) => http.post(`crawl/keyword/save`,params)
export const sub = (params) => http.get(`job/type/sub`,params)